import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// api
import { forgotPassword } from "../../api/users";

// lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";


const initialFormValue = {
  email: "",
};

const ForgotPassForm = () => {


  //hooks
  const navigate = useNavigate();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [errors, setErrors] = useState({});
  const { email } = formValue;

  //function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, ...{ [name]: value } });
    if (!isEmpty(errors)) {
      setErrors({});
    }
  };

  const handleSubmit = async (e) => {
    try {
      const { status, message, errors } = await forgotPassword({ email });
      if (status == "success") {
        setFormValue(initialFormValue);
        toastAlert("success", message, "signup", "TOP_CENTER");
      } else {
        if (errors) {
          setErrors(errors);
          return false;
        }
        toastAlert("error", message, "signup", "TOP_CENTER");
      }
    } catch (err) { }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-10 m-auto">
          <div className="dash_box mb-0">
            <div className="row align-items-center">
              <div className="col-lg-6 text-center d-none d-lg-block">
                <img
                  src={require("../../assets/images/login_img.png")}
                  alt="Image"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-6">
                <div className="inner_title_wrapper mt-2">
                  <h2 className="inner_title">Forgot Password</h2>
                  <p>
                    Reset Password link has been send to your registered Email
                    Address
                  </p>
                </div>
                <form className="auth_form">
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter Email Address"
                      name="email"
                      onChange={handleChange}
                      value={email}
                    />
                    <span className="text-danger">{errors?.email}</span>
                  </div>
                  <div className="text-center mt-4 mb-4">
                    <button
                      type="button"
                      class="w-100 primary_btn"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="d-flex">
                    <Link class="ml-auto" to="/login">Sign In</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassForm;
