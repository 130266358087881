import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

//import api
import { UnstakeOrder } from "../../api/staking";

//import lib
import isEmpty from "../../lib/isEmpty";
import { toFixedDown } from "../../lib/roundOf";
import { toastAlert } from "../../lib/toastAlert";



const initialFormValue = {
    currencyId: "",
    amount: 0
}

const UnStakeModal = (props) => {

    //props
    const { isShow, record, onHide } = props

    //hooks
    const dispatch = useDispatch()

    //state
    const [loader, setLoader] = useState(false)
    const [formValue, setFormValue] = useState(initialFormValue)
    const { currencyId, amount } = formValue;
    const [address, setAddress] = useState("")
    const [duration, setDuration] = useState(0)





    //function

    const hanldeClose = () => {
        onHide()
        setFormValue(initialFormValue)
    }






    const handleSubmit = async () => {
        try {
            setLoader(true)
            let reqData = {
                currencyId: record?._id,
                amount: toFixedDown((record?.stakeAmount * record?.penalty / 100), 8)
            }
            const { status, error, loading, message } = await UnstakeOrder(reqData, dispatch)
            setLoader(loading)
            if (status == 'success') {
                hanldeClose()
                toastAlert("error", message, "login");
            } else {
                toastAlert("error", message, "login");
            }
        } catch (err) {
            console.log(err, 'error')
        }
    }




    useEffect(() => {
        if (!isEmpty(record)) {
            let data = !isEmpty(record.address) ? record.address : record.adminAddress
            setAddress(data)


            const highestAmountStake = record.stakeArray.length > 0 && record.stakeArray.reduce((max, stake) =>
                stake.amount > max.amount ? stake : max,
                record.stakeArray[0]
            );
            setDuration(highestAmountStake?.duration)
        }


    }, [record, isShow])

    // console.log(record, '---------record')

    return (
        <>
            <Modal show={isShow}
                onHide={hanldeClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered >
                <Modal.Header closeButton onClick={hanldeClose} >
                    <Modal.Title><h4 className="modal-title mt-0">
                        Unstake Immediately </h4> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="assets_form">
                        <h5 className="text-center">{duration} days remaining for unstake</h5>
                        <h5 className="text-center">In order to unstake the locked tokens, the penalty has to be sent </h5>
                        <h5 className="text-center">to wallet :&nbsp;&nbsp;&nbsp;{address}</h5>
                        <h5 className="text-center">Amount: {toFixedDown((record?.stakeAmount * record?.penalty / 100), 8)} {record?.coin}</h5>
                        <div className="asset_modal_btn_grp mt-4 mb-4">

                            <button type="button"
                                onClick={handleSubmit}
                                className="w-100 primary_btn primary_btn_with_bg primary_btn_transform">
                                {loader && <i class="fas fa-spinner fa-spin"></i>}    I paid the penalties
                            </button>
                            <button type="button"
                                className="w-100 primary_btn primary_btn_transform"
                                onClick={hanldeClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default UnStakeModal;