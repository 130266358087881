

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CurrencyFormat from 'react-currency-format';

//import api
import { getStaking } from "../../api/staking"

//import lib
import isEmpty from "../../lib/isEmpty";
import { toFixedDown, truncateDecimals } from "../../lib/roundOf";

//import modal
import StakingModal from "./StakingModal";

const initialFormValue = {
    isModalOpen: false,
    record: {},
    type: "fixed"
};

const StakingList = () => {



    //redux
    const walletData = useSelector((state) => state.wallet);
    const priceConversion = useSelector((state) => state.priceConversion);

    //statte
    const [tab, setTab] = useState("fixed");
    const [list, setList] = useState([]);
    const [stakeData, setStakeData] = useState(initialFormValue);
    const [filter, setFilter] = useState(true)
    const [loader, setLoading] = useState(true);
    //function
    const handleSubmit = (e) => {
        setTab(e)
    }

    const handleSearch = (e) => {
        let { name, value } = e.target;
        function getSearch(item) {
            return (
                item.coin.toLowerCase() == value?.toLowerCase()
            );
        }
        let filterDoc = list.filter(getSearch);
        if (!isEmpty(filterDoc)) {
            setFilter(true)
            setList(filterDoc);
        } else {
            setFilter(false)
        }

        if (isEmpty(value)) {
            setFilter(true)
            getStakeData();
        }
    };

    const getStakeData = async () => {
        let reqData = {
            type: tab
        }
        setLoading(true)
        const { status, result } = await getStaking(reqData)
        if (status === 'success') {
            // console.log(result, 'result')
            let tempArr = [...result];
            walletData?.length >= 0 &&
                walletData.map((item, index) => {
                    let pairIndex =
                        tempArr &&
                        tempArr.findIndex((el) => {
                            return el.currencyId == item._id;
                        });
                    if (pairIndex >= 0 && !isEmpty(pairIndex)) {
                        let PriceCnv = priceConversion.find(
                            (el) =>
                                el.baseSymbol == item.coin && el.convertSymbol == "USD"
                        );
                        tempArr[pairIndex] = {
                            ...tempArr[pairIndex],
                            ...{
                                stakeBal: parseFloat(item.stakeBal - item.lockedBal),
                                convertPrice: !isEmpty(PriceCnv?.convertPrice) ? parseFloat(PriceCnv.convertPrice) : 1,
                                USDValue: !isEmpty(PriceCnv?.convertPrice)
                                    ? parseFloat(item.stakeBal - item.lockedBal) *
                                    parseFloat(PriceCnv.convertPrice)
                                    : parseFloat(item.stakeBal - item.lockedBal)
                            },
                        };
                    }
                });
            setList(tempArr)
        }
        setLoading(false)
    }

    const handleCloseModal = () => {
        setStakeData({ isModalOpen: false, record: {}, type: "" });
    };

    useEffect(() => {
        getStakeData()
    }, [tab, walletData, priceConversion])


    return (
        <>
            <StakingModal
                isShow={stakeData.isModalOpen}
                record={stakeData.record}
                type={stakeData.type}
                onHide={handleCloseModal}
            />
            <div className="staking_top_flex">
                <ul
                    class="nav nav-pills custom_nav_tabs"
                    id="pills-tab"
                    role="tablist"
                >
                    {/* <li class="nav-item" role="presentation">
                        <button
                            class={`nav-link ${tab == 'flexible' && 'active'}`}
                            id="pills-flexible-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-flexible"
                            type="button"
                            role="tab"
                            aria-controls="pills-flexible"
                            aria-selected="true"
                            onClick={() => handleSubmit("flexible")}
                        >
                            Flexible
                        </button>
                    </li> */}
                    <li class="nav-item" role="presentation">
                        <button
                            class={`nav-link ${tab == 'fixed' && 'active'}`}
                            id="pills-fixed-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-fixed"
                            type="button"
                            role="tab"
                            aria-controls="pills-fixed"
                            aria-selected="false"
                            onClick={() => handleSubmit("fixed")}
                        >
                            Fixed
                        </button>
                    </li>

                </ul>
                <div className="search_grp">
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={handleSearch}
                        className="form-control"
                    />
                    <img
                        src={require("../../assets/images/search_icon.png")}
                        alt="Search"
                        className="img-fluid search_icon"
                    />
                </div>

            </div>

            <div class="tab-content" id="pills-tabContent">
                {/* <div
                    class="tab-pane fade show active"
                    id="pills-flexible"
                    role="tabpanel"
                    aria-labelledby="pills-flexible-tab"
                >
                    <div className="row">
                        {list && list.length > 0 && list.map((item, i) => {
                            // console.log(item,'item')
                            if (filter) {
                                return (
                                    <div className="col-md-6 col-xl-3 col-lg-3">
                                        <div className="dash_box dash_box_right">
                                            <div className="dash_box_right_top flex-dashbox-top">
                                                <div className="crypto-img-name">
                                                    <img
                                                        src={item.image}
                                                        alt="Crypto"
                                                        className="img-fluid"
                                                    />

                                                    <h4>{item.coin}</h4>
                                                </div>
                                                <b style={{ "position": "absolute", right: "36px" }}> <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(item?.convertPrice, 6)} /></b>
                                            </div>
                                            <div className="dash_box_right_bottom">
                                                <div>
                                                    <label>Available Balance</label>
                                                    <b><CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(item?.stakeBal, 2)} /> {item.coin}</b>
                                                </div>
                                                <div>
                                                    <label>Available Balance USDT</label>
                                                    <b>$ {item?.USDValue ? <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(item.USDValue, 2)} /> : 0}</b>
                                                </div>
                                                <div>
                                                    <label> APY</label>
                                                    <label className="txt_green">{item.flexibleAPY} %</label>
                                                </div>
                                            </div>
                                            <div className="button_grp justify-content-center">
                                                <button
                                                    className="primary_btn primary_btn_transform"
                                                    onClick={() => {
                                                        setStakeData({
                                                            isModalOpen: true,
                                                            record: item,
                                                            type: "flexible"
                                                        });
                                                    }}
                                                >
                                                    Stake Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                        {!loader && list && list.length == 0 || !filter && <span>There are no records to display</span>}
                        {loader && (
                            <span style={{ textAlign: "center", display: "inline-block" }}>
                                Loading...
                            </span>
                        )}
                    </div>
                </div> */}
                <div
                    class="tab-pane fade show active"
                    id="pills-fixed"
                    role="tabpanel"
                    aria-labelledby="pills-fixed-tab"
                >
                    <div className="row">
                        {list && list.length > 0 && list.map((item, i) => {
                            let data = item.periodList.find((el) => el.days === '360')
                            if (filter) {
                                return (
                                    <div className="col-md-6 col-xl-3 col-lg-3">
                                        <div className="dash_box dash_box_right">
                                            <div className="dash_box_right_top flex-dashbox-top">
                                                <div className="crypto-img-name">
                                                    <img
                                                        src={item.image}
                                                        alt="Crypto"
                                                        className="img-fluid"
                                                    />
                                                    <h4>{item.coin}</h4>
                                                </div>
                                                <b style={{ "position": "absolute", right: "36px" }}><CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(item?.convertPrice, 6)} /></b>
                                            </div>
                                            <div className="dash_box_right_bottom">
                                                <div>
                                                    <label>Available Balance</label>
                                                    <b><CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(item?.stakeBal, 2)} /> {item.coin}</b>
                                                </div>
                                                <div>
                                                    <label>Available Balance USDT</label>
                                                    <b>$ {item?.USDValue ? <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(item?.USDValue, 2)} /> : 0}</b>
                                                </div>
                                                <div>
                                                    <label>APY</label>
                                                    <label>{data?.APY} %</label>
                                                </div>
                                            </div>
                                            <div className="button_grp justify-content-center">
                                                <button
                                                    className="primary_btn primary_btn_transform"
                                                    onClick={() => {
                                                        setStakeData({
                                                            isModalOpen: true,
                                                            record: item,
                                                            type: "fixed"
                                                        });
                                                    }}
                                                >
                                                    Stake Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                        }
                        {!loader && list && list.length == 0 || !filter && <span>There are no records to display</span>}
                        {loader && (
                            <span style={{ textAlign: "center", display: "inline-block" }}>
                                <i class="fas fa-spinner fa-spin"></i> Loading...
                            </span>
                        )}
                    </div>
                </div>
            </div>

        </>
    )
}

export default StakingList;
