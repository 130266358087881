import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import NavbarInner from "../components/Navbar-Inner";
import DataTable from "react-data-table-component";

// Images
import copy_icon from "../assets/images/copy_icon.svg";
import { getCMSPage } from "../api/users.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Privacy(props) {

  const [content, setContent] = useState("");
  const { isAuth } = useSelector((state) => state.auth);
  // function
  const fetchCmsPage = async () => {
    try {
      const { status, result } = await getCMSPage("privacy_policy");
      // console.log("result", result);
      if (status == "success") {
        setContent(result);
        // console.log("content", content);
        document.title = result.title;
        const el = document.querySelector("meta[name='description']");
        el.setAttribute("content", result.metadescription);
        document.keywords = el;
        // console.log("el", el);
        const els = document.querySelector("meta[name='keywords']");
        els.setAttribute("content", result.metakeywords);
        document.description = els;
        // console.log("", els);
      }
    } catch (err) { }
  };

  useEffect(() => {
    fetchCmsPage();
  }, []);

  return (
    <div>
      <ScrollToTopOnMount />
      {isAuth ? <NavbarInner /> : <Navbar />}
      <div className="innerpages_wrapper">
        <div className="container">
          {/* <div className="inner_title_wrapper">
            <h2 className="inner_title">Privacy Policy</h2>
          </div> */}
          <div className="dash_wrapper">
            <div className="row">
            <div
                  dangerouslySetInnerHTML={{
                    __html: content && content.content,
                  }}
                />
              {/* <div className="col-xl-12">
                <p>Last Updated: 21 August 2023</p>
                <p>Effective Date: 31 August 2023</p>
                <h2>What is Lorem Ipsum</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum
                </p>
                <ul>
                  <li>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy.
                  </li>
                  <li>
                    When an unknown printer took a galley of type and scrambled
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged. It was
                    popularised in the 1960s with the release of Letraset sheets
                    containing Lorem Ipsum passages.
                  </li>
                  <li>
                    More recently with desktop publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum
                  </li>
                  <li>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy.
                  </li>
                  <li>
                    When an unknown printer took a galley of type and scrambled
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged. It was
                    popularised in the 1960s with the release of Letraset sheets
                    containing Lorem Ipsum passages.
                  </li>
                  <li>
                    More recently with desktop publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* <NavbarFixed /> */}
    </div>
  );
}
