import React, { useState, useEffect } from "react";
import { useTheme } from "./ThemeContext";

export default function ThemeToggle(props) {
  const { theme, toggleTheme } = useTheme();

  useEffect(() => {
    // Apply the current theme when the component mounts
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <button className="toggletheme_btn" onClick={toggleTheme}>
      {theme === "light" ? (
        <i class="bi bi-sun-fill"></i>
      ) : (
        <i class="bi bi-moon-fill"></i>
      )}
    </button>
  );
}
